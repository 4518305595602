var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "http403-container" }, [
      _c("div", { staticClass: "bullshit" }, [
        _c("div", { staticClass: "bullshit__oops" }, [_vm._v("OOPS!")]),
        _c("div", { staticClass: "bullshit__headline" }, [
          _vm._v("你没有权限去该页面"),
        ]),
        _c("div", { staticClass: "bullshit__info" }, [
          _vm._v("如有不满请联系你领导分配访问权限"),
        ]),
        _c(
          "a",
          { staticClass: "bullshit__return-home", attrs: { href: "/" } },
          [_vm._v("返回首页")]
        ),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _vm._v("Powered by "),
        _c(
          "a",
          { attrs: { href: "http://www.startcms.cn", target: "_blank" } },
          [_vm._v("StartCMS")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }