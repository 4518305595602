"use strict";

var _interopRequireWildcard = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _microApp = _interopRequireWildcard(require("@micro-zoe/micro-app"));
var _vuex = require("vuex");
var _components = require("./components");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  components: {
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Setting: _components.Setting,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    Breadcrumb: _Breadcrumb.default
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['apps', 'user', 'token', 'authorize'])), (0, _vuex.mapState)({
    layout: function layout(state) {
      return state.core.config.layout;
    },
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSetting: function showSetting(state) {
      return state.setting.showSetting;
    },
    needTagsView: function needTagsView(state) {
      return state.setting.tagsView;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        top: this.layout === 'top',
        left: this.layout === 'left',
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        hasTagsView: this.needTagsView,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    path: function path() {
      return this.$route.path;
    },
    app: function app() {
      var name = this.$route.meta.app ? this.$route.meta.app : '';
      var app = this.apps.find(function (item) {
        return item.name == name;
      });
      if (typeof app == 'undefined' || app.name == 'ai') {
        return {
          name: '',
          entry: '/'
        };
      }
      if (app.entry.indexOf('http') == -1) {
        app.entry = window.location.origin + app.entry;
      }
      // 关闭沙箱模式后绑定通讯对象
      if (!app.sandbox) {
        window[app.name + 'EventCenter'] = new _microApp.EventCenterForMicroApp(app.name);
      }
      return app;
    }
  }),
  watch: {
    $route: function $route() {
      this.setAppData();
    }
  },
  methods: {
    closeSideBar: function closeSideBar() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    created: function created(e) {
      // console.log('元素被创建', e)
    },
    beforemount: function beforemount(e) {
      this.setAppData();
      // console.log(e.detail.name, '即将被渲染',e)
    },
    mounted: function mounted(e) {
      // console.log(e.detail.name, '已经渲染完成',e)
    },
    unmount: function unmount(e) {
      // console.log(e.detail.name, '已经卸载',e)
    },
    error: function error(e) {
      // console.log(e.detail.name, '渲染出错', e)
    },
    // 下发子应用数据
    setAppData: function setAppData() {
      if (!this.app.name) {
        return;
      }
      _microApp.default.setData(this.app.name, {
        app: this.app,
        user: this.user,
        token: this.token,
        route: this.$route,
        authorize: this.authorize
      });
    },
    // 监听子应用数据
    onDataChange: function onDataChange(e) {
      var data = e.detail.data;
      // 控制基座路由
      if (data.route) {
        this.$router.push({
          path: data.route.path || '/',
          hash: data.route.hash || '',
          query: data.route.query || {},
          params: data.route.params || ''
        });
      }
      // 监听应用消息
      if (data.msg) {
        this.$store.dispatch('app/sendMsg', data.msg);
      }
    }
  }
};
exports.default = _default;