"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkLogin = checkLogin;
exports.getApiTicket = getApiTicket;
exports.getAuthUrl = getAuthUrl;
exports.getQrcode = getQrcode;
var _request = _interopRequireDefault(require("@/utils/request"));
function getQrcode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/official/oauth/qrcode',
    method: 'get',
    params: params
  });
}
function checkLogin() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/official/oauth/checkLogin',
    method: 'get',
    params: params
  });
}
function getAuthUrl() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: "/official/oauth/getAuthUrl",
    method: "get",
    params: params
  });
}
function getApiTicket() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: "/official/oauth/getApiTicket",
    method: "get",
    params: params
  });
}