"use strict";

var _interopRequireWildcard = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("D:\\www\\cms\\app\\ai\\base\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("D:\\www\\cms\\app\\ai\\base\\node_modules\\core-js\\modules\\es6.promise.js");
require("D:\\www\\cms\\app\\ai\\base\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("D:\\www\\cms\\app\\ai\\base\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _elementUi = _interopRequireDefault(require("element-ui"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _microApp = _interopRequireDefault(require("@micro-zoe/micro-app"));
require("normalize.css/normalize.css");
require("@/styles/index.scss");
require("element-ui/lib/theme-chalk/display.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _auth = _interopRequireDefault(require("@/directive/auth"));
require("./icons");
require("./auth");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _vueSocket = _interopRequireDefault(require("vue-socket.io"));
// a modern alternative to CSS resets
// global css

// 水波纹指令

// 权限指令

// icon
// auth control
// error log

// global filters

_vue.default.use(_auth.default);
_vue.default.use(_waves.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});

_vue.default.use(new _vueSocket.default({
  debug: false,
  // ws://43.155.162.197:7002/ 线上key测试
  connection: "wss://openai.simplestart.cn/",
  options: {
    autoConnect: false,
    transports: ["websocket"],
    query: {
      token: _store.default.state.user.token
    }
  },
  extraHeaders: {
    "Access-Control-Allow-Origin": "*"
  }
}));

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_microApp.default.start();
new _vue.default({
  router: _router.default,
  store: _store.default,
  sockets: {
    // connecting() {
    //   console.log("正在连接");
    // },
    // connect() {
    //   console.log("连接成功");
    // },
    // disconnect() {
    //   console.log("断开连接");
    // },
    // connect_failed() {
    //   console.log("连接失败");
    // },
    // error() {
    //   console.log("错误发生，并且无法被其他事件类型所处理");
    // },
    // reconnecting() {
    //   console.log("正在重连");
    // },
    // reconnect_failed() {
    //   console.log("重连失败");
    // },
    // reconnect() {
    //   console.log("重连成功");
    // },
    // welcome: (data) => {
    //   console.log("welcome data", data);
    // },
  },
  render: function render(h) {
    return h(_App.default);
  }
}).$mount("#start");