var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "padding-sm", attrs: { gutter: 20 } },
    [
      _c("div", { staticClass: "padding solid-bottom bg-white" }, [
        _c("span", { staticClass: "margin-left-sm" }, [_vm._v("应用收藏")]),
      ]),
      _vm.pageData.data.length != 0
        ? _c("el-empty", {
            staticClass: "bg-white",
            attrs: { description: "暂无记录" },
          })
        : _vm._l(_vm.pageData.data, function (item, index) {
            return _c(
              "el-col",
              {
                key: index,
                staticClass: "padding bg-white",
                attrs: { span: 24, lg: 6, md: 12, sm: 12 },
              },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "app-item",
                    attrs: {
                      shadow: "hover",
                      "body-style": { padding: "0px" },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "app-cover pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onShowInfo(item)
                          },
                        },
                      },
                      [
                        _c("el-image", { attrs: { src: item.image } }, [
                          _c(
                            "div",
                            {
                              staticClass: "image-error",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline",
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "app-price" }, [
                          _vm._v(_vm._s(_vm._f("priceFilter")(item.price))),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "app-info" }, [
                      _c("h3", [_vm._v(_vm._s(item.name))]),
                      _c(
                        "div",
                        { staticClass: "app-meta" },
                        [
                          _c("el-rate", {
                            attrs: { value: item.rating || 5.0, disabled: "" },
                          }),
                          _c("span", { staticClass: "app-collect" }, [
                            _vm._v(_vm._s(item.fav_count) + "人收藏"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "app-actions flex justify-around align-center",
                      },
                      [
                        item.favorite
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  size: "small",
                                  icon: "el-icon-star-on",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onFavorite(index)
                                  },
                                },
                              },
                              [_vm._v("已收藏")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  size: "small",
                                  icon: "el-icon-star-off",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onFavorite(index)
                                  },
                                },
                              },
                              [_vm._v("收藏")]
                            ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-s-promotion",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.gotoWebsite(item)
                              },
                            },
                          },
                          [_vm._v("访问")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          }),
      _c("el-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          { staticClass: "margin flex justify-center align-center" },
          [
            _c("el-pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageData.total > 0,
                  expression: "pageData.total > 0",
                },
              ],
              attrs: {
                "current-page": _vm.pageData.page,
                "page-size": _vm.pageData.per_page,
                total: _vm.pageData.total,
                background: "",
                layout: "total, pager, prev, next",
              },
              on: { "current-change": _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }