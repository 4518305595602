var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { attrs: { id: "pageContainer" } },
    [
      _c("div", {
        staticClass: "login-bg",
        style: { backgroundImage: "url(" + _vm.backgroundImage + ")" },
      }),
      _c("canvas", { attrs: { id: "trails" } }),
      _c("canvas", { attrs: { id: "particles" } }),
      _c("el-header", { class: _vm.device }, [_c("logo")], 1),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { class: _vm.device, attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { md: 10, sm: 20, offset: 2 } }, [
                _c("div", {
                  staticClass: "text-content text-white",
                  domProps: { innerHTML: _vm._s(_vm.outputText) },
                }),
              ]),
              _vm.device == "mobile"
                ? _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { sm: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "auth-login",
                          attrs: { plain: "" },
                          on: { click: _vm.authLogin },
                        },
                        [_vm._v(_vm._s(_vm.loginBtn))]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "loginForm",
                          staticClass: "login-form",
                          attrs: {
                            rules: _vm.loginRules,
                            model: _vm.loginForm,
                            autocomplete: "on",
                            "label-position": "left",
                            size: "medium",
                          },
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "el-tabs",
                              model: {
                                value: _vm.formType,
                                callback: function ($$v) {
                                  _vm.formType = $$v
                                },
                                expression: "formType",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "扫码登录", name: "scan" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-center qr-box" },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "qrcode margin-tb",
                                          attrs: {
                                            src: _vm.loginQrCode.url,
                                            lazy: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-picture-outline",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-df text-white" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "打开微信扫一扫，快速登录/注册"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "密码登录", name: "login" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "account" } },
                                    [
                                      _vm.formType == "login"
                                        ? _c("el-input", {
                                            ref: "account",
                                            attrs: {
                                              placeholder: "账户名/手机号",
                                              name: "account",
                                              type: "text",
                                              tabindex: "1",
                                              autocomplete: "on",
                                            },
                                            model: {
                                              value: _vm.loginForm.account,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "account",
                                                  $$v
                                                )
                                              },
                                              expression: "loginForm.account",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "password" } },
                                    [
                                      _c("el-input", {
                                        key: _vm.passwordType,
                                        ref: "password",
                                        attrs: {
                                          type: _vm.passwordType,
                                          placeholder: "登录密码",
                                          name: "password",
                                          tabindex: "2",
                                          autocomplete: "on",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleLogin($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.loginForm.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.loginForm,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "loginForm.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "code" } },
                                    [
                                      _c("el-input", {
                                        ref: "captcha",
                                        attrs: {
                                          placeholder: "验证码",
                                          name: "code",
                                          tabindex: "3",
                                          autocomplete: "off",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleLogin($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.loginForm.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.loginForm, "code", $$v)
                                          },
                                          expression: "loginForm.code",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "change-code",
                                          on: { click: _vm.handleImage },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              name: "uniqid",
                                            },
                                            domProps: {
                                              value: _vm.captcha.uniqid,
                                            },
                                          }),
                                          _c("img", {
                                            attrs: { src: _vm.captcha.image },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "30px",
                                      },
                                      attrs: {
                                        loading: _vm.btnLoading,
                                        type: "primary",
                                        size: "medium",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                    },
                                    [_vm._v("登录")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    name: "register",
                                    label: "手机登陆",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "mobile" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-container" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-mobile-phone",
                                          }),
                                        ]
                                      ),
                                      _vm.formType == "register"
                                        ? _c("el-input", {
                                            ref: "mobile",
                                            attrs: {
                                              placeholder: "手机号码",
                                              name: "mobile",
                                              type: "text",
                                              size: "medium",
                                              tabindex: "1",
                                              autocomplete: "on",
                                            },
                                            model: {
                                              value: _vm.loginForm.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression: "loginForm.mobile",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "code" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-container" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-message",
                                          }),
                                        ]
                                      ),
                                      _c("el-input", {
                                        ref: "captcha",
                                        attrs: {
                                          placeholder: "验证码",
                                          name: "code",
                                          size: "medium",
                                          tabindex: "2",
                                          autocomplete: "off",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleRegister($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.loginForm.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.loginForm, "code", $$v)
                                          },
                                          expression: "loginForm.code",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "change-code",
                                          on: { click: _vm.handleCode },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled: _vm.btnDisabled,
                                                type: "text",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.codeBtn))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "tips text-right text-df" },
                                    [
                                      _c("span", [
                                        _vm._v("未注册手机号验证后自动注册"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "30px",
                                      },
                                      attrs: {
                                        loading: _vm.btnLoading,
                                        type: "primary",
                                        size: "medium",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleRegister($event)
                                        },
                                      },
                                    },
                                    [_vm._v("注册/登录")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-footer", [
        _c("div", { staticClass: "copyright", class: _vm.device }, [
          _vm._v("Code by chatgpt | Powered by "),
          _c(
            "a",
            { attrs: { href: "http://www.simplestart.cn", target: "_blank" } },
            [_vm._v("Simplestart")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }