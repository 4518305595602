// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --myStyle: \"\" ;\n}", ""]);
// Exports
exports.locals = {
	"menuText": "#d3dfed",
	"menuActiveText": "#FFFFFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "200px",
	"myStyle": "var(--myStyle)"
};
module.exports = exports;
