"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _store = _interopRequireDefault(require("@/store"));
var _user = require("@/api/user");
var _official = require("@/api/official");
var _cookie = require("@/utils/cookie");
var _elementUi = require("element-ui");
// get token from cookie

var maxWidth = 992; // refer to Bootstrap's responsive design
var _default = {
  data: function data() {
    return {
      device: "destop"
    };
  },
  watch: {},
  beforeMount: function beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },
  created: function created() {
    if (this.$_isMobile()) {
      this.device = "mobile";
      var href = window.location.href;
      if ((0, _cookie.getToken)()) {
        if (href.indexOf("code=") > -1) {
          href = href.split("?");
          var params = {};
          for (var i in href) {
            var keys = href[i].split("&");
            for (var k in keys) {
              k = keys[k].split("=");
              if (k.length > 1) {
                params[k[0]] = k[1];
              }
            }
          }
          // href 跳转
          if (params.href) {
            href = decodeURIComponent(params.href);
            href = href.indexOf("?") > -1 ? href + "&code=" + params.code : href + "?code=" + params.code;
            window.location.replace(href);
            return;
          }
          // code 清除
          if (params.code) {
            delete params.code;
            var query = Object.keys(params).map(function (key) {
              return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
            }).join("&");
            this.$router.push({
              path: '/ai/chat',
              query: query
            });
            // window.location.replace(window.location.origin + window.location.pathname + (query ? "?" + query : ""));
            return;
          }
        } else {
          window.location.href = "/web/ai/chat";
        }
      } else if (href.indexOf("code=") > -1) {
        this.authLogin();
      }
    }
  },
  methods: {
    $_isMobile: function $_isMobile() {
      var rect = document.body.getBoundingClientRect();
      return rect.width - 1 < maxWidth;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        this.device = this.$_isMobile() ? "mobile" : "destop";
        if (this.device == "mobile") {}
      }
    },
    // 授权登录
    authLogin: function authLogin() {
      var _this = this;
      if (!(0, _cookie.getToken)()) {
        var href = window.location.href;
        var hash = window.location.hash;
        if (href.indexOf("code=") < 0) {
          // 一级跳转：获取授权链接并跳转获取code
          // 临时设置app_id
          (0, _official.getAuthUrl)({
            href: encodeURIComponent(href),
            app_id: 'wx06d65621385fbbc8'
          }).then(function (res) {
            if (!res.code) {
              window.location.replace(res.data);
            } else {
              (0, _elementUi.Message)({
                type: "error",
                message: '无法获取授权地址',
                showClose: true,
                duration: 3 * 1000
              });
            }
          });
        } else {
          href = href.split("?");
          var params = {
            // 临时设置
            app_id: 'wx06d65621385fbbc8'
          };
          for (var i in href) {
            var keys = href[i].split("&");
            for (var k in keys) {
              k = keys[k].split("=");
              if (k.length > 1) {
                params[k[0]] = k[1];
              }
            }
          }
          // 二级跳转(用于微信防封)
          if (params.code && typeof params.href !== "undefined") {
            href = decodeURIComponent(params.href);
            href = href.indexOf("?") > -1 ? href + "&code=" + params.code : href + "?code=" + params.code;
            window.location.replace(href);
            return false;
          }
          this.loginBtn = '登录中...';
          (0, _user.authLogin)(params).then(function (res) {
            if (!res.code && res.data.token) {
              _store.default.dispatch("user/setToken", res.data.token);
              // 三级跳转(清理授权code)
              delete params.code;
              delete params.redirect;
              var query = Object.keys(params).map(function (key) {
                return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
              }).join("&");
              _this.loginBtn = '登录成功！正在跳转...';
              _this.$router.push({
                path: '/ai/chat'
              });
              return;
            } else {
              (0, _elementUi.Message)({
                type: "error",
                message: '授权登录失败:' + res.msg,
                showClose: true,
                duration: 3 * 1000
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: '/ai/chat'
        });
      }
    }
  }
};
exports.default = _default;