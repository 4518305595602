var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "color:" +
        _vm.textColor +
        ";background-color:" +
        _vm.backgroundColor +
        ";",
    },
    [
      _c("logo", { staticClass: "logo", attrs: { collapse: _vm.isCollapse } }),
      _c("app-menu", { staticClass: "app-menu" }),
      _c("profile", {
        staticClass: "profile",
        attrs: { textColor: _vm.textColor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }