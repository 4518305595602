var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.closeSideBar },
          })
        : _vm._e(),
      _vm.layout === "left"
        ? _c("sidebar", { staticClass: "layout-sidebar" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "layout-header" },
        [
          _vm.layout === "top"
            ? _c("navbar", { staticClass: "layout-navbar" })
            : _vm._e(),
          _vm.needTagsView
            ? _c("tags-view", { attrs: { collapse: !_vm.sidebar.opened } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "layout-container" },
        [
          _vm.app.name == "" || _vm.app.entry == "" || _vm.app.entry == "/"
            ? _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: _vm.cachedViews } },
                    [_c("router-view", { key: _vm.path })],
                    1
                  ),
                ],
                1
              )
            : _c("micro-app", {
                attrs: {
                  name: _vm.app.name,
                  url: _vm.app.entry,
                  line: _vm.app.debug,
                  "keep-alive": !_vm.app.debug,
                  ssr: _vm.app.ssr,
                  disableScopecss: !Boolean(_vm.app.scopecss),
                  disableSandbox: Boolean(_vm.app.sandbox),
                  baseRoute: "/web",
                },
                on: {
                  datachange: _vm.onDataChange,
                  created: _vm.created,
                  beforemount: _vm.beforemount,
                  mounted: _vm.mounted,
                  unmount: _vm.unmount,
                  error: _vm.error,
                },
              }),
        ],
        1
      ),
      _vm.showSetting ? _c("right-panel", [_c("setting")], 1) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }