"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.get = get;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.set = set;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// token存储key
var tokenKey = "User-Token";
// token在Cookie中存储的天数，默认3天
var expireDay = 3;

/**
 * 设置值
 */
function set(key, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return _jsCookie.default.set(key, value, options);
}

/**
 * 获取值
 */
function get(key) {
  return _jsCookie.default.get(key);
}

/**
 * 获取token
 */
function getToken() {
  return _jsCookie.default.get(tokenKey);
}

/**
 * 设置token
 * @param {*} token
 */
function setToken(token) {
  return _jsCookie.default.set(tokenKey, token, {
    expires: expireDay
  });
}

/**
 * 删除token
 */
function removeToken() {
  return _jsCookie.default.remove(tokenKey);
}
var _default = {
  set: set,
  get: get,
  setToken: setToken,
  getToken: getToken,
  removeToken: removeToken
};
exports.default = _default;