"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.favorite = favorite;
exports.getCategory = getCategory;
exports.getInfo = getInfo;
exports.getPage = getPage;
exports.getPricing = getPricing;
var _request = _interopRequireDefault(require("@/utils/request"));
function getCategory() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: "/tools/api/category",
    method: "get",
    params: params
  });
}
function getPricing() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: "/tools/api/pricing",
    method: "get",
    params: params
  });
}
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: "/tools/api/page",
    method: "get",
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: "/tools/api/info",
    method: "get",
    params: {
      id: id
    }
  });
}
function favorite(data) {
  return (0, _request.default)({
    url: "/tools/api/favorite",
    method: "post",
    data: data
  });
}