"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authLogin = authLogin;
exports.getState = getState;
exports.login = login;
exports.logout = logout;
exports.mobileLogin = mobileLogin;
exports.register = register;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取账户登录态
function getState() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/core/user/state',
    method: 'get',
    params: params
  });
}
/**
 * 账户登录
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
function login(data) {
  return (0, _request.default)({
    url: '/core/user/login',
    method: 'post',
    data: data
  });
}

/**
 * 授权登录
 * @param {code, user_info} param
 * @returns
 */
function authLogin(data) {
  return (0, _request.default)({
    url: '/official/oauth/login',
    method: 'POST',
    data: data
  });
}
/**
 * 手机登录
 * @param {}
 * @returns 
 */
function mobileLogin(data) {
  return (0, _request.default)({
    url: "/core/user/mobileLogin",
    method: "post",
    data: data
  });
}
;

/**
 * 用户注册
 * @param { }
 * @returns 
 */
function register(data) {
  return (0, _request.default)({
    url: "/core/user/register",
    method: "post",
    data: data
  });
}
;

/**
 * 用户登出
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
function logout(data) {
  return (0, _request.default)({
    url: '/core/user/logout',
    method: 'post',
    data: data
  });
}