"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _Menu = _interopRequireDefault(require("./Menu"));
var _Profile = _interopRequireDefault(require("../Profile"));
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Logo: _Logo.default,
    AppMenu: _Menu.default,
    Profile: _Profile.default
  },
  data: function data() {
    return {
      list: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['config', 'sidebar', 'appNavbar'])), {}, {
    layout: function layout() {
      return this.config.layout;
    },
    textColor: function textColor() {
      return this.config.text_color;
    },
    backgroundColor: function backgroundColor() {
      return this.config.background_color;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {}
};
exports.default = _default;